/** ============================================ µ
 * @Name        Store JSX
 * @Description Library | Storage
 * @Author      𝔐𝔞𝔯𝔱í𝔫 𝔖𝔦𝔩𝔳𝔞 🇲🇽
 * @Homepage    [MartinSilvaMX](https://martinsilva.mx)
 * @Email       [Email](mailto:silvamart@gmail.com)
 * @Location    Guadalajara, Jalisco, México
 * @CreatedAt   2017-08-XX
 * @UpdatedAt   2021-04-24
 * ============================================ */
const Store = {
  set(key, value) {
    // Check browser support
    if (typeof Storage !== "undefined") {
      // Store
      var cache = [];
      localStorage.setItem(
        key,
        JSON.stringify(value, function (key, value) {
          if (typeof value === "object" && value !== null) {
            if (cache.indexOf(value) !== -1) {
              // Circular reference found, discard key
              return;
            }
            // Store value in our collection
            cache.push(value);
          }
          return value;
        })
      );
      cache = null; // Enable garbage collection
      // Retrieve
      return Store.get(key);
    } else {
      console.warn("Sorry, your browser does not support Web Storage...");
      return;
    }
  },
  get(key) {
    // Check browser support
    if (typeof Storage !== "undefined") {
      // Retrieve
      const result = localStorage.getItem(key);
      try {
        if (result) {
          return JSON.parse(result);
        } else {
          return result;
        }
      } catch (ex) {
        console.warn(ex, result);
        return result;
      }
    } else {
      console.warn("Sorry, your browser does not support Web Storage...");
      return;
    }
  },
  remove(key) {
    // Check browser support
    if (typeof Storage !== "undefined") {
      // Retrieve
      return localStorage.removeItem(key);
    } else {
      console.warn("Sorry, your browser does not support Web Storage...");
      return;
    }
  }
};
/** ============================================ */
export { Store as default, Store }
/** ============================================ */
