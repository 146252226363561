/** ============================================ µ
 * @Description DOM [JS] Library
 * @Author      𝔐𝔞𝔯𝔱í𝔫 𝔖𝔦𝔩𝔳𝔞 🇲🇽
 * @Homepage    https://martinsilva.mx
 * @Email       silvamart@gmail.com
 * @Location    Guadalajara, Jalisco, México
 * @CreatedAt   2021-04-22
 * @UpdatedAt   2021-04-24
 * ============================================ */

/* IMPORTS ==================================== */

import Memory from './Memory.lib';

/* METHODS ==================================== */

/**
 * @param {*} $scroller Scroller component.
 * @returns {function} On event function.
 */
function scrollToBottom($scroller) {
  return event => {
    $scroller.scrollTop = $scroller.scrollHeight;
  }
}

/**
 * @param {string} key 
 * @returns {function|component}
 */
function setRef(key = '') {
  return reference => {
    Memory.set(key, reference);
  }
}

/**
 * @param {string} key 
 * @returns {function|component}
 */
function getRef(key = '') {
  return (
    Memory.get(key) ||
    {
      value: '',
      focus: () => null,
    }
  );
}

function cleanHTML(html) {
  return html
    .split(/\n|\s/)
    .filter(i => !!i.trim())
    .join(" ")
    .replace(/>\s*</g, "><");
}

/* DICTIONARY ================================= */

const DomLib = {
  scrollToBottom,
  getRef,
  setRef,
  cleanHTML
}

/* EXPORTS ==================================== */

export {
  DomLib as default,
  DomLib,
  getRef as $,
}

/* ============================================ */
