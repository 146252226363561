/** ============================================ µ
 * @Description Data [JS] 
 *              Chronos Library
 * @Author      𝔐𝔞𝔯𝔱í𝔫 𝔖𝔦𝔩𝔳𝔞 🇲🇽
 * @Homepage    https://martinsilva.mx
 * @Email       silvamart@gmail.com
 * @Location    Guadalajara, Jalisco, México
 * @CreatedAt   2021-04-21
 * @UpdatedAt   2021-04-24
 * ============================================ */

/* IMPORTS ==================================== */

import moment from 'moment';
import pack from '../../package.json';
import Store from '../library/Storage.lib';
import DOM, { $ } from './DOM.lib';
import Format from './Format.lib';
import Units from './Units.lib';

/* CONSTANTS ================================== */

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm:ss.SSS';
const UI_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
const UI_TIME_FORMAT = 'HH:mm';
window['moment'] = moment;

/* METHODS ==================================== */

/**
 * @returns {array} Log
 */
function getLog() {
  return (Store.get('log') || []);
}

/**
 * 
 * @returns 
 */
function getEntries(selectedDate) {
  const log = getLog();
  return log.filter(n => !!n.date.match(selectedDate));
}

/**
 * @returns {array} List of dates
 */
function getDatesList() {
  const hashList = {};
  const log = getLog();
  log.forEach(n => (
    hashList[moment(n.date).format(DATE_FORMAT)] = true
  ));
  const result = Object.keys(hashList).reverse();
  result.unshift('');
  return (!!result.length ? result : []);
}

/**
 * 
 * @param {*} param0 
 */
function addEntry({
  entry = {},
  selectedDate = '',
  selectedTime = '',
  callback = () => null
}) {
  function getDateTime(date) {
    return (
      date
        ? `${date}T${moment().format(`${TIME_FORMAT}Z`)}`
        : moment().format(`${DATE_FORMAT}T${TIME_FORMAT}Z`)
    );
  }
  let date = getDateTime(selectedDate);
  if (selectedTime) {
    date = moment(
      `${moment(selectedDate).format(DATE_FORMAT)} ${selectedTime}`
    ).format(
      `${DATE_FORMAT}T${TIME_FORMAT}Z`
    );
  }
  const log = removeEntry({
    entry: {
      date,
      ...entry
    }
  });
  Store.set('log',
    [...log, { date, ...entry, },].sort(
      (a, b) => String(a.date).localeCompare(b.date)
    )
  );
  return callback();
}

/**
 * 
 * @param {*} param0 
 */
function removeEntry({
  entry = {},
  callback = () => null
}) {
  console.debug('remove', { entry });
  const log = DataLib.getLog();
  const results = Store.set('log',
    [...log].filter(n => n.date !== entry.date)
  );
  callback(results);
  return results;
}

function getLastReview() {
  return moment(pack.lastReview).format(UI_DATETIME_FORMAT);
}

function getAppState() {
  return (
    Store.get('appState') ||
    {
      selectedDate: moment().format(DATE_FORMAT),
      theme: 'dark',
    }
  );
}

function saveAppState(state) {
  // console.debug('µ:saveAppState', state);
  Store.set('appState', state);
}

function getNewSelectedDate(selectedDate, fncName) {
  const today = moment().format(DATE_FORMAT);
  const currentDate = selectedDate || today;
  return (
    fncName
      ? moment(currentDate)[fncName](1, 'day').format(DATE_FORMAT)
      : moment().format(DATE_FORMAT)
  );
}

function getSelectedDay(selectedDate) {
  return moment(selectedDate).format('dddd');
}

function getCurrentTime() {
  return moment().format(TIME_FORMAT);
}

function getCurrentTimeUI(date) {
  return moment().format(UI_TIME_FORMAT);
}

function getEntryTime({ date }) {
  return moment(date).format(TIME_FORMAT);
}

function getEntryTimeUI({ date }) {
  return moment(date).format(UI_TIME_FORMAT);
}

function getJourneySummaryPlaintextLog(selectedDate) {
  const entries = DataLib.getEntries(selectedDate);
  return [
    moment(selectedDate).format(`${DATE_FORMAT} dddd`),
    entries.map(n => (
      [
        moment(n.date).format(UI_TIME_FORMAT),
        n.message
      ].join('\n')
    )).join('\n\n'),
    $('summary').textContent,
    [
      'Requested at',
      moment().format()
    ].join('\n')
  ].join('\n\n')
}

/**
 * 
 * @param {string} selectedDate
 * @returns 
 */
function getJourneySummary(selectedDate) {

  const entries = DataLib.getEntries(selectedDate);

  const time = {
    journey: 0,
    budget: Units.milliseconds({ hours: 4 }),
    rested: 0,
    worked: 0,
    overBudget: 0,
    remainingBudget: 0,
  };

  let {
    lastEntry = {},
    currentEntry = {},
    startingJourney = {},
    doneJourney = {},
  } = {};

  while (!!entries.length) {

    currentEntry = entries.shift();

    const {
      // date: currentDate = '', // not in use
      message: currentMessage = ''
    } = currentEntry;

    const {
      // date: lastDate = '', // not in use
      message: lastMessage = ''
    } = lastEntry;

    if (currentMessage.match(/starting journey/i)) {
      startingJourney = currentEntry;
    }
    if (currentMessage.match(/done journey/i)) {
      doneJourney = currentEntry;
    }

    if (currentMessage.match(/rest/i) && !entries.length) {
      time.rested += (
        moment()
          .diff(currentEntry.date, 'milliseconds')
      );
    }
    else if (lastMessage.match(/rest/i)) {
      time.rested += (
        moment(currentEntry.date)
          .diff(lastEntry.date, 'milliseconds')
      );
    }

    lastEntry = currentEntry;

  }

  const {
    date: startingDate = moment(),
  } = startingJourney;

  const {
    date: doneDate = moment(),
  } = doneJourney;

  time.worked = (
    moment(doneDate)
      .diff(startingDate, 'milliseconds')
    - time.rested
  );

  time.journey = (time.worked + time.rested);
  time.overBudget = (time.worked - time.budget);
  time.remainingBudget = (time.budget - time.worked);
  time.isOverBudget = (time.overBudget > 0);

  const {
    journeyHoursFormat = Format.hours({ milliseconds: time.journey }),
    budgetHoursFormat = Format.hours({ milliseconds: time.budget }),
    restedHoursFormat = Format.hours({ milliseconds: time.rested }),
    workedHoursFormat = Format.hours({ milliseconds: time.worked }),
    overBudgetHoursFormat = Format.hours({
      milliseconds: time.overBudget,
      extended: true,
    }),
    remainingBudgetHoursFormat = Format.hours({
      milliseconds: time.remainingBudget,
      extended: true,
    }),
    isOverBudget = time.isOverBudget
  } = {};

  const plainTextLine = DOM.cleanHTML([
    'Total hours:',
    `${journeyHoursFormat} journey`,
    `${restedHoursFormat} rested`,
    `${workedHoursFormat} worked`,
    `${budgetHoursFormat} budget`,
    `${isOverBudget
      ? (`
          <span class="c-red">
            ${overBudgetHoursFormat} over budget
          </span>
        `)
      : (`
          <span class="c-green">
            ${remainingBudgetHoursFormat} remaining budget
          </span>
        `)
    }
  `].join(' | ')
  )

  return {
    journeyHoursFormat,
    budgetHoursFormat,
    restedHoursFormat,
    workedHoursFormat,
    overBudgetHoursFormat,
    remainingBudgetHoursFormat,
    isOverBudget,
    time,
    plainTextLine,
  };

}

/* DICTIONARY ================================= */

const DataLib = {
  getLog,
  getEntries,
  getDatesList,
  addEntry,
  removeEntry,
  getLastReview,
  getAppState,
  saveAppState,
  getNewSelectedDate,
  getSelectedDay,
  getCurrentTime,
  getCurrentTimeUI,
  getEntryTime,
  getEntryTimeUI,
  getJourneySummaryPlaintextLog,
  getJourneySummary,
};

/* EXPORTS ==================================== */

export {
  DataLib as default,
  DataLib,
};

/* ============================================ */
