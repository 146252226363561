/** ============================================ 🇲🇽
 * @Name        copyText JS 👑
 * @Description Tool function
 * @Author      𝔐𝔞𝔯𝔱í𝔫 𝔖𝔦𝔩𝔳𝔞
 * @Homepage    [MartinSilvaMX](https://martinsilva.mx)
 * @Email       [Email](mailto:silvamart@gmail.com)
 * @Location    Guadalajara, Jalisco, México
 * @CreatedAt   2021-03-15
 * @UpdatedAt   2021-03-15
 * @see         copyHTML
/** ============================================ */
/** @param {string} text it will be copied to the clipboard! */
export default function copyText(text = '') {
  return new Promise((resolve) => {
    const wrapper = document.createElement('pre');
    wrapper.setAttribute('class', 'wrapperCopy');
    wrapper.setAttribute(
      'style',
      'color: rgba(0,0,0,0.8) !important; font-size: 1rem;'
    );
    wrapper.addEventListener('click', doCopy);
    wrapper.addEventListener('fakeclick', doCopy);
    wrapper.innerHTML = text;
    document.body.appendChild(wrapper);
    function doCopy() {
      window.getSelection().removeAllRanges();
      const range = document.createRange();
      range.selectNode(document.querySelector('.wrapperCopy'));
      window.getSelection().addRange(range);
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          resolve({ text, successful });
        }
        else{
          console.debug('µ:copyText', { text, successful });
          throw new Error(successful);
        }
      } catch (ex) {
        console.error(ex);
      }
      window.getSelection().removeAllRanges();
      document.body.removeChild(wrapper);
    }
    var event = new Event('fakeclick');
    wrapper.dispatchEvent(event);
  });
}
