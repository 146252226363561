/** ============================================ µ
 * @Description Memory [JS] Library
 * @Author      𝔐𝔞𝔯𝔱í𝔫 𝔖𝔦𝔩𝔳𝔞 🇲🇽
 * @Homepage    https://martinsilva.mx
 * @Email       silvamart@gmail.com
 * @Location    Guadalajara, Jalisco, México
 * @CreatedAt   2021-04-17
 * @UpdatedAt   2021-04-17
 * ============================================ */

/* CONSTANTS ================================== */

const currentTime = (new Date()).getTime();
window[`µ:${currentTime}`] = {};

/* METHODS ==================================== */

/**
 * SET a key value pair in a global variable.
 * 
 * @param {string} key
 * @param {any} value
 */
function setValue(key = '', value = {}) {
  return (
    window[`µ:${currentTime}`][key] = value
  );
}

/**
 * GET a value set in a global variable.
 * 
 * @param {string} key
 */
function getValue(key = '') {
  return window[`µ:${currentTime}`][key];
}

/* DICTIONARY ================================= */

const MemoryLib = {
  set: setValue,
  get: getValue,
}

/* EXPORTS ==================================== */

export {
  MemoryLib as default,
  MemoryLib as GlobalMemory,
  setValue as setGlobalValue,
  getValue as getGlobalValue,
}

/* ============================================ */

