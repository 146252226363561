/** ============================================ µ
 * @Description Format [JS] Library
 * @Author      𝔐𝔞𝔯𝔱í𝔫 𝔖𝔦𝔩𝔳𝔞 🇲🇽
 * @Homepage    https://martinsilva.mx
 * @Email       silvamart@gmail.com
 * @Location    Guadalajara, Jalisco, México
 * @CreatedAt   2021-04-22
 * @UpdatedAt   2021-04-24
 * ============================================ */

import Units from "./Units.lib";

/* METHODS ==================================== */

/**
 * 
 * @param {*} n 
 * @param {*} width 
 * @param {*} z 
 * @returns 
 */
function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

/**
 * 
 * @param {*} minutes 
 * @returns 
 */
function minutesToHours(minutes) {
  return (`
    ${pad(Math.floor(minutes / 60), 2)}:${pad(minutes % 60, 2)}
  `).trim();
}

/**
 * 
 * @param {*} seconds 
 * @returns 
 */
function secondsToHours(seconds) {
  return (`
    ${pad(Math.floor(seconds / (60 * 60)), 2)}:${pad(Math.floor(seconds % (60 * 60) / 60), 2)}:${pad(seconds % 60, 2)}
  `).trim();
}

/**
 * 
 * @param {*} seconds 
 * @returns 
 */
function millisecondsToHours(seconds) {
  return (`
    ${pad(Math.floor(seconds / (60 * 60 * 1000)), 2)}:${pad(Math.floor(seconds % (60 * 60 * 1000) / 60), 2)}:${pad(seconds % 60, 2)}
  `).trim();
}

function Hours({ milliseconds, extended }) {
  const hours = Units.hours({ milliseconds });
  const minutes = Units.minutes({ milliseconds }) % Units.minutes({ hours: 1 });
  const seconds = Units.seconds({ milliseconds }) % Units.seconds({ minutes: 1 });
  return (
    extended
      ? (
        `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`
      ).trim()
      : (
        `${pad(hours, 2)}:${pad(minutes, 2)}`
      ).trim()
  );
}

/* DICTIONARY ================================= */

const FormatLib = {
  pad,
  minutesToHours,
  secondsToHours,
  millisecondsToHours,
  hours: Hours,
}

/* EXPORTS ==================================== */

export {
  FormatLib as default,
  FormatLib,
}

/* ============================================ */
