/** ============================================ µ
 * @Description Memory [JS] Library
 * @Author      𝔐𝔞𝔯𝔱í𝔫 𝔖𝔦𝔩𝔳𝔞 🇲🇽
 * @Homepage    https://martinsilva.mx
 * @Email       silvamart@gmail.com
 * @Location    Guadalajara, Jalisco, México
 * @CreatedAt   2021-04-17
 * @UpdatedAt   2021-04-17
 * ============================================ */

/* IMPORTS ==================================== */

import * as React from 'react';

/* METHODS ==================================== */

function useReact(props) {
  const {
    initialState = {},
    onMounting = () => null,
    afterRendered = () => null,
    onUnmounting = () => null,
  } = props;
  const [state, setState] = React.useState(initialState);
  const ref = React.useRef({
    components: {}
  });
  ref.current.onMounting = onMounting;
  ref.current.onUnmounting = onUnmounting;
  ref.current.state = state;
  React.useEffect(() => {
    // console.debug('µ:mounting', ref.current.state);
    const current = ref.current;
    current.onMounting(current.state);
    return () => {
      // console.debug('µ:unmounting', current.state);
      current.onUnmounting(current.state);
    }
  }, []);
  React.useEffect(() => {
    // console.debug('µ:afterRender', state);
    if (ref.current.afterUpdate) {
      ref.current.afterUpdate(state);
    }
    afterRendered(state);
  });
  async function updateState(newState, afterUpdate) {
    ref.current.afterUpdate = afterUpdate;
    return setState({
      ...state,
      ...newState,
    });
  }
  function setReference(key = '') {
    return reference => {
      ref.current.components[key] = reference;
    }
  }
  function getReference(key = '') {
    return ref.current.components[key];
  }
  return {
    state,
    updateState,
    setReference,
    getReference,
  };
}

/* DICTIONARIES =============================== */

const ReactLib = {
  useReact
}

/* EXPORTS ==================================== */

export {
  ReactLib as default,
  ReactLib,
  useReact,
};

/* ============================================ */