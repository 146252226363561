/** =========================================== 🇲🇽
 * @Name        ChronosApp JS 👑
 * @Description The React Index
 * @Author      𝔐𝔞𝔯𝔱í𝔫 𝔖𝔦𝔩𝔳𝔞
 * @Homepage    [MartinSilvaMX](https://martinsilva.mx)
 * @Email       [eMail](mailto:silvamart@gmail.com)
 * @Location    Guadalajara, Jalisco, México
 * @CreatedAt   2021-03-05
 * @UpdatedAt   2021-04-24
 * ============================================ */

/* IMPORTS ==================================== */

import React from 'react';
import './css/main.css';
import './css/theme-dark.css';
import './css/theme-light.css';
import Data from './library/Data.lib';
import DOM, { $ } from './library/DOM.lib';
import Memory from './library/Memory.lib';
import { useReact } from './library/React.lib';
import copyText from './tools/copyText.tool';

/* METHODS ==================================== */

/**
 * MAIN METHOD
 */
function App() {
  const initialState = Data.getAppState();
  function onMounting() {
    Memory.set('interval',
      setInterval(() => {
        const time = Data.getCurrentTimeUI();
        if (!App.selectedTime) {
          $('timeInput').value = time;
        }
        $('summary').innerHTML = (
          Data.getJourneySummary(selectedDate)
            .plainTextLine
        );
      }, 1000)
    );
  };
  function afterRendered(state) {
    Data.saveAppState(state);
  };
  function onUnmounting() {
    clearInterval(Memory.get('interval'));
  };

  const {
    state,
    updateState,
  } = useReact({
    initialState,
    onMounting,
    afterRendered,
    onUnmounting,
  });

  const {
    selectedDate,
    theme,
    inputValue = '',
  } = state;

  const entries = Data.getEntries(selectedDate);

  const dates = Data.getDatesList();

  const $Input = $('input');

  function reRender() {
    updateState(
      { updatedAt: (new Date()).toISOString() },
      DOM.scrollToBottom($('scroller'))
    );
  }

  function onClickAddToInput(phrase = '') {
    return async event => {
      if (!!String($Input.value).trim().length) {
        const value = inputValue.split(' ');
        value.push(phrase.replace('{{break line}}', '\n'));
        await updateState({
          inputValue: (
            value
              .join(' ')
              .split('\n')
              .map(n => (
                n.trim()
                  .replace(
                    /^\w/,
                    c => c.toUpperCase()
                  )
              )).join('\n')
          )
        });
      }
      else {
        await updateState({
          inputValue: phrase.replace(/^\w/, (c) => c.toUpperCase())
        });
      }
      $Input.focus();
    }
  }

  async function onClickCopy(event) {
    const result = await copyText(
      Data.getJourneySummaryPlaintextLog(
        selectedDate
      )
    );
    console.debug('µ:onClickCopy');
    console.debug(result?.text);
    if (!!result) {
      alert('copied to clipboard');
    }
  }

  function onClickEdit(entry) {
    return event => {
      const TimeInput = $('timeInput');
      App.selectedTime = Data.getEntryTime(entry);
      TimeInput.value = Data.getEntryTimeUI(entry);
      updateState({
        inputValue: entry.message
      });
    }
  }

  function onClickCopyItem({ date, message }) {
    return async event => {
      const result = await copyText(message);
      if (!!result) { alert('copied to clipboard'); }
    }
  }

  function onClickRemoveEntry({ date }) {
    return event => Data.removeEntry({
      entry: { date },
      callback: reRender
    });
  }

  function onClickSocial(platform) {
    return event => {

    }
  }

  function onClickNavigateDate(fncName = ('add' || 'subtract')) {
    return event => {
      updateState({
        selectedDate: Data.getNewSelectedDate(selectedDate, fncName)
      }, DOM.scrollToBottom($('scroller')));
    }
  }

  function onChangeTextArea(event) {
    updateState({ inputValue: event.target.value });
  }

  async function onSubmit(event) {
    event.preventDefault();
    if (!!inputValue.trim()) {
      await Data.addEntry({
        entry: { message: inputValue },
        selectedDate,
        selectedTime: App.selectedTime,
      });
      delete App.selectedTime;
      await updateState(
        { inputValue: '' },
        DOM.scrollToBottom($('scroller'))
      );
      $Input.focus();
    }
  }
  function onClickToggleTheme(event) {
    updateState({
      theme: (theme === 'light')
        ? 'dark'
        : 'light'
    });
  }
  return (
    <div className={`App ${theme}`}>

      <div className="µ-bg">
        <div className="µ-bg-image" />
      </div>

      {/** PAPER */}
      <div className="µ-paper d-flex flex-column scroll-y">

        {/** HEADER */}
        <div className="d-flex align-center flex-wrap mb-1">
          <div className="col mr-auto">
            <div className="d-flex align-center">
              <h1 className="mr-2">Chronos</h1>
              <button
                className="µ-button-icon material-icons"
                onClick={onClickToggleTheme}>
                brightness_4
            </button>
            </div>
            <small className="p-1">
              Latest code version: {Data.getLastReview()}
            </small>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col mr-1">
              <small className="c-royal-blue">Archive</small>
              <select
                id="dates"
                name="dates"
                value={selectedDate}
                onChange={event => {
                  const selectedDate = event.target.value;
                  if (!!selectedDate) {
                    updateState(
                      { selectedDate },
                      DOM.scrollToBottom($('scroller'))
                    );
                  }
                  else {
                    updateState(
                      { selectedDate: Data.getNewSelectedDate() },
                      DOM.scrollToBottom($('scroller'))
                    );
                  }
                }}
                className="s-m-input">
                {dates.map((n, index) => (
                  <option value={n} key={`date_${index}`}>
                    {n}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <small className="c-royal-blue">
                Selected Date
              </small>
              <div className="d-flex w-100 align-center">
                <input
                  type="date"
                  value={selectedDate}
                  onChange={event => {
                    updateState(
                      { selectedDate: event.target.value },
                      DOM.scrollToBottom($('scroller'))
                    );
                  }}
                  className="s-m-input mr-1"
                />
                <div>
                  <button
                    className="µ-button-icon material-icons"
                    onClick={event => {
                      updateState(
                        { selectedDate: Data.getNewSelectedDate() },
                        DOM.scrollToBottom($('scroller'))
                      );
                    }}>
                    today
                  </button>
                </div>
              </div>
              <div
                className="d-flex align-center space-between"
                style={{ marginRight: '2.5rem' }}>
                <small
                  className="material-icons pointer"
                  onClick={onClickNavigateDate('subtract')}>
                  navigate_before
                </small>
                <small>
                  {Data.getSelectedDay(selectedDate)}
                </small>
                <small
                  className="material-icons pointer"
                  onClick={onClickNavigateDate('add')}>
                  navigate_next
                </small>
              </div>
            </div>
          </div>
        </div>

        {/** LIST */}
        <div
          ref={DOM.setRef('scroller')}
          className="scroll-y rounded p-1 input mb-1"
          style={{
            minHeight: '40vh',
            borderRadius: '8px',
          }}>
          {entries.map((entry, index) => (
            <div key={`log / ${index}`} className="mb-1">
              {index !== 0 && <hr />}
              <h5>{Data.getEntryTimeUI(entry)}</h5>
              <div className="d-flex align-center">
                <pre
                  className="mr-auto"
                  style={{ maxWidth: 'calc(100% - 8.7rem)' }}>
                  {entry.message}
                </pre>
                <button
                  className="µ-button-icon material-icons mr-1"
                  onClick={onClickEdit(entry)}>
                  mode_edit
                </button>
                <button
                  className="µ-button-icon material-icons mr-1"
                  onClick={onClickCopyItem(entry)}>
                  content_copy
                </button>
                <button
                  className="µ-button-icon material-icons"
                  onClick={onClickRemoveEntry(entry)}>
                  delete
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* SUMMARY */}
        <div className="d-flex align-center mb-1">
          <small ref={DOM.setRef('summary')} className="ml-auto c-gray f-italic mr-1" />
          <button
            className="µ-button-icon material-icons"
            onClick={onClickCopy}>
            content_copy
          </button>
        </div>

        {/* INPUT */}
        <form onSubmit={onSubmit} className="d-flex mb-1">
          <textarea
            name="message"
            ref={DOM.setRef('input')}
            className="w-100 mr-1"
            value={inputValue}
            onChange={onChangeTextArea}
          />
          {!!inputValue.trim() && (
            <small
              className="material-icons pointer mr-1"
              onClick={event => {
                updateState(
                  { inputValue: '' },
                  () => $Input.focus()
                );
              }}>
              cleaning_services
            </small>
          )}
          <div className="col justify-center">
            <input
              type="time"
              ref={DOM.setRef('timeInput')}
              onChange={ev => (App.selectedTime = ev.target.value)}
              className="s-m-input mb-1" />
            <button className="s-m-button">SEND</button>
          </div>
        </form>

        {/* ACTION TOOLS */}
        <div className="d-flex flex-wrap">
          <div className="col mr-1">
            {([
              'starting',
              'done',
              'reviewing',
              'working on',
              'committing',
              'making PR',
            ].map((item, index) => (
              <button className="s-m-button mb-1 capitalize"
                onClick={onClickAddToInput(item)}
                key={`c1b${index}: ${item}`}>
                {item}
              </button>
            )))}
          </div>
          <div className="col mr-1">
            {([
              'journey',
              'story -',
              'looking for tasks',
              'rest',
              'back to work',
              '{{break line}}',
            ].map((item, index) => (
              <button className="s-m-button mb-1 capitalize"
                onClick={onClickAddToInput(item)}
                key={`c2b${index}: ${item}`}>
                {item}
              </button>
            )))}
          </div>
          <div className="col mr-1">
            {([
              'writing with',
              'whereby with',
              'hangout with',
              'zoom with',
              'meeting with',
            ].map((item, index) => (
              <button className="s-m-button mb-1 capitalize"
                onClick={onClickAddToInput(item)}
                key={`c3b${index}: ${item}`}>
                {item}
              </button>
            )))}
          </div>
          <div className="col mr-1">
            {([
              'AC',
              'BB',
              'OM',
              'SU',
            ].map((item, index) => (
              <button className="s-m-button mb-1 capitalize"
                onClick={onClickAddToInput(item)}
                key={`c4b${index}: ${item}`}>
                {item}
              </button>
            )))}
          </div>
        </div>

      </div>

      {/* FOOTER */}
      <div className="d-flex fixed-bottom w-100 scroll-x p-1">
        <div className="d-flex ml-auto">
          {[
            {
              label: 'Email',
              materialIcon: 'email',
              url: 'mailto:mgodevs@gmail.com'
            },
            {
              label: 'PayPal',
              unicode: <>&#xf1ed;</>,
              url: 'https://paypal.me/MartinSilvaMX'
            },
            {
              label: 'MartinSilvaMX',
              iconURL: 'https://martinsilva.mx/mci/icono.bmp',
              url: 'https://martinsilva.mx'
            },
            {
              label: 'My Anime List',
              iconURL: 'https://cdn.myanimelist.net/images/favicon.ico',
              url: 'https://myanimelist.net/animelist/MartinSilvaMX'
            },
            {
              label: 'YouTube',
              unicode: <>&#xf167;</>,
              url: 'https://youtube.com/c/MartinSilvaMX'
            },
            {
              label: 'Facebook',
              unicode: <>&#xf09a;</>,
              url: 'https://facebook.com/MartinSilvaMX'
            },
            {
              label: 'Twitter',
              unicode: <>&#xf099;</>,
              url: 'https://twitter.com/MartinSilvaMX'
            },
            {
              label: 'GitHub',
              unicode: <>&#xf09b;</>,
              url: 'https://github.com/MartinSilvaMX'
            },
            {
              label: 'LinkedIn',
              unicode: <>&#xf08c;</>,
              url: 'https://linkedin.com/in/MartinSilvaMX'
            },
            {
              label: 'React',
              unicode: <>&#xf41b;</>,
              url: 'https://create-react-app.dev/'
            },
            {
              label: 'JavaScript',
              unicode: <>&#xf3b9;</>,
              url: 'https://en.wikipedia.org/wiki/JavaScript'
            }
          ].map((
            {
              label,
              unicode,
              iconURL,
              materialIcon,
              url
            },
            index
          ) => (
            <a key={`social${index}`}
              href={url}
              rel="noreferrer"
              target="_blank">
              <button
                title={label}
                className={
                  !!materialIcon
                    ? 'µ-button-icon material-icons mr-1'
                    : "µ-button-icon fab mr-1"
                }
                onClick={onClickSocial(label)}>
                {(materialIcon
                  ? materialIcon
                  : unicode
                    ? unicode
                    : (
                      <img
                        src={iconURL}
                        alt={label}
                      />
                    )
                )}
              </button>
            </a>
          ))}
        </div>
      </div>

    </div >
  );
}

/** =========================================== */
export default App;
/** =========================================== */