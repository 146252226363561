/** ============================================ µ
 * @Description Units [JS] Library
 * @Author      𝔐𝔞𝔯𝔱í𝔫 𝔖𝔦𝔩𝔳𝔞 🇲🇽
 * @Homepage    https://martinsilva.mx
 * @Email       silvamart@gmail.com
 * @Location    Guadalajara, Jalisco, México
 * @CreatedAt   2021-04-24
 * @UpdatedAt   2021-04-24
 * ============================================ */

/* METHODS ==================================== */

function Hours({ hours = 0, minutes = 0, seconds = 0, milliseconds = 0 }) {
  if (hours > 0) {
    return Math.floor(hours);
  }
  if (minutes > 0) {
    return Math.floor(minutes / Minutes({ hours: 1 }));
  }
  if (seconds > 0) {
    return Math.floor(seconds / Seconds({ hours: 1 }));
  }
  if (milliseconds > 0) {
    return Math.floor(milliseconds / Milliseconds({ hours: 1 }));
  }
  return 0;
}

function Minutes({ hours = 0, minutes = 0, seconds = 0, milliseconds = 0 }) {
  if (hours > 0) {
    return Math.floor(hours * 60);
  }
  if (minutes > 0) {
    return Math.floor(minutes);
  }
  if (seconds > 0) {
    return Math.floor(seconds / Seconds({ minutes: 1 }));
  }
  if (milliseconds > 0) {
    return Math.floor(milliseconds / Milliseconds({ minutes: 1 }));
  }
  return 0;
}

function Seconds({ hours = 0, minutes = 0, seconds = 0, milliseconds = 0 }) {
  if (hours > 0) {
    return Math.floor(hours * 60 * 60);
  }
  if (minutes > 0) {
    return Math.floor(minutes * 60);
  }
  if (seconds > 0) {
    return Math.floor(seconds);
  }
  if (milliseconds > 0) {
    return Math.floor(milliseconds / Milliseconds({ seconds: 1 }));
  }
  return 0;
}

function Milliseconds({ hours = 0, minutes = 0, seconds = 0, milliseconds = 0 }) {
  if (hours > 0) {
    return Math.floor(hours * 60 * 60 * 1000);
  }
  if (minutes > 0) {
    return Math.floor(minutes * 60 * 1000);
  }
  if (seconds > 0) {
    return Math.floor(seconds * 1000);
  }
  if (milliseconds > 0) {
    return Math.floor(milliseconds);
  }
  return 0;
}

/* DICTIONARY ================================= */

const UnitsLib = {
  hours: Hours,
  minutes: Minutes,
  seconds: Seconds,
  milliseconds: Milliseconds,
}

/* EXPORTS ==================================== */

export {
  UnitsLib as default,
  UnitsLib,
}

/* ============================================ */